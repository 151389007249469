import moment from 'moment-timezone';

import i18n from '@src/i18n';
import { getDefaultLocale } from '@tools/utils';

export const Moment = (
    np?: moment.MomentInput,
    format?: moment.MomentFormatSpecification,
    language?: string,
    strict?: boolean,
) => {
    // ensures the integration tests are working
    const i18nLanguage = (i18n && i18n.language) || getDefaultLocale();

    return moment(np, format, language, strict).locale(i18nLanguage);
};

// this function to round down time to interval minutes
// use to calculate the Time of Use time range
export const roundDownInterval = (date: Date, interval: number) => {
    return moment(date)
        .clone()
        .minutes(Math.floor(moment(date).minutes() / interval) * interval)
        .seconds(0)
        .milliseconds(0);
};

export default Moment;
