export enum BatteryStatus {
    Idle = 'Idle',
    Charging = 'Charging',
    Discharging = 'Discharging',
    Error = 'Error',
    FullyCharged = 'Fully charged',
}

export type BatteryChargingStatus =
    | BatteryStatus.Idle
    | BatteryStatus.Charging
    | BatteryStatus.Discharging;

export const enum BatteryColor {
    WHITE = 'white',
    GRAY = 'gray',
    TURQUOISE = 'turquoise',
    TURQUOISE_LIGHT = 'turquoise-light',
    RED = 'red',
}

export const enum PanelColor {
    WHITE = 'color-white',
    GRAY = 'light-grey',
    TURQUOISE = 'color-turquoise',
    RED = 'color-red-dark',
}

export const enum EnergyActionTypes {
    ERROR = 'PVBATTERY_ERROR',
    STATUS = 'PVBATTERY_STATUS',
    LOADING = 'STATUS_LOADING',
    SET_PV_PEAK = 'SET_PV_PEAK',
    SET_PV_PANEL = 'SET_PV_PANEL',
    SET_METER_DEVICES = 'SET_METER_DEVICES',
    SET_GATEWAY_DEVICES = 'SET_GATEWAY_DEVICES',
    SET_PVBATTERY_DEVICES = 'SET_PVBATTERY_DEVICES',
    SET_HEAT_PUMP_DEVICES = 'SET_HEAT_PUMP_DEVICES',
}

export const enum InverterProviders {
    Fronius = 'fronius',
    Kiwigrid = 'kiwigrid',
    Givenergy = 'givenergy',
    Goodwe = 'goodwe',
    Kostal = 'kostal',
    Nordic = 'nordic',
    SolarEdge = 'solaredge',
    SolaX = 'solax',
    Solis = 'solis',
    Sungrow = 'sungrow',
    UKMeter = 'ukmeter',
    Zucchetti = 'zucchetti',
    GridX = 'gridx',
}

export enum HeatPumpStateEnum {
    UNKNOWN_APPLIANCE_STATE = 'UNKNOWN_APPLIANCE_STATE',
    SCANNED = 'SCANNED',
    CONNECTING = 'CONNECTING',
    VERIFYING = 'VERIFYING',
    UNTRUSTED = 'UNTRUSTED',
    CONNECTED = 'CONNECTED',
    DISCONNECTED = 'DISCONNECTED',
}

export enum InverterStateEnum {
    VERIFYING = 'VERIFYING',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
}
