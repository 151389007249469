import { TimeOfUseDecisionsModel } from '@swagger-http';
import { TimeOfUseActionTypes } from './enums';
import { TimeOfUseAction, TimeOfUseState } from './types';

export const initialState: TimeOfUseState = {
    error: false,
    status: undefined,
    batteryOptimizationState: {
        locked: true,
        activated: undefined,
    },
    batteryOptimizationDecision: {
        from: undefined,
        to: undefined,
        status: undefined,
        additionalInfo: {
            from: undefined,
            to: undefined,
            motives: [],
            price: {},
        },
    },
    readiness: {
        isTouReady: true,
        reasons: [],
    },
};

const isNewerDecision = (
    state: TimeOfUseState,
    decision?: TimeOfUseDecisionsModel,
): boolean => {
    return !!(
        (!state.batteryOptimizationDecision?.from && decision) ||
        (decision?.from &&
            state.batteryOptimizationDecision?.from &&
            new Date(decision.from) >
                new Date(state.batteryOptimizationDecision.from))
    );
};

export default function reducer(
    state: TimeOfUseState = initialState,
    action: TimeOfUseAction,
): TimeOfUseState {
    switch (action.type) {
        case TimeOfUseActionTypes.TOU_STATUS:
            return {
                ...state,
                error: false,
                status: action.payload.status,
            };
        case TimeOfUseActionTypes.TOU_BATTERY_STATE:
            return {
                ...state,
                error: false,
                batteryOptimizationState:
                    action.payload.batteryOptimizationState,
            };
        case TimeOfUseActionTypes.TOU_BATTERY_DECISION:
            if (
                isNewerDecision(
                    state,
                    action.payload.batteryOptimizationDecision,
                )
            ) {
                return {
                    ...state,
                    error: false,
                    batteryOptimizationDecision:
                        action.payload.batteryOptimizationDecision,
                };
            }

            return state;
        case TimeOfUseActionTypes.TOU_BATTERY_ERROR:
            return {
                ...state,
                error: true,
            };
        case TimeOfUseActionTypes.TOU_READINESS:
            return {
                ...state,
                readiness: {
                    ...state.readiness,
                    ...action.payload,
                },
            };

        default:
            return state;
    }
}
