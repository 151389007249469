import { EventsResponseModel } from '@swagger-sse/api';

import {
    TimeOfUseActionTypes,
    WebsocketMessageSubTypeEnum,
} from '@store/enums';
import { LiveDataEvent, LiveDataAction, TimeOfUseState } from '@store/types';

const { TIME_OF_USE_BATTERY_DECISION } = WebsocketMessageSubTypeEnum;

export class GatewayEvent implements LiveDataEvent {
    private eventData: EventsResponseModel;

    constructor(data: any) {
        this.eventData = data;
    }

    public getAction(): LiveDataAction {
        const {
            eventData: { data, messageSubType },
        } = this;
        let payload: Partial<TimeOfUseState> = {};

        if (messageSubType === (TIME_OF_USE_BATTERY_DECISION as any)) {
            payload = {
                batteryOptimizationDecision: data,
            };
        }

        return {
            type: TimeOfUseActionTypes.TOU_BATTERY_DECISION,
            payload,
        };
    }
}
